<template>
    <div class="mainSpyContainer">
        <Turler1></Turler1>
    </div>
</template>

<script>
    import Turler1 from './Turler1';


    export default {
        components: {
            Turler1
        }
    }
</script>



